import WorldIcon from "assets/icons/language-filter/world.svg"
import CheckmarkIcon from 'assets/icons/language-filter/checkmark.svg';
import styles from "./language-filter.module.scss"

const formatSelectedOption = ({ value, label, description }, { context, selectValue }) => {
	const selectedValue = selectValue.length ? selectValue[0].value : "xx" // deliberately won't match any known language
	const isSelectedValue = value === selectedValue

	if (context == 'value') { // displayed as the selected item up top
		return (
			<div className={styles.languageFilterValueContainerContents}>
				<span className={styles.languageFilterIcon}><WorldIcon className="svg" /></span>
				<span className={styles.languageFilterControlText}>{label}</span>
			</div>
		)
	}
	else {  // displays the items in the actual dropdown menu
		return (
			<div className={styles.languageFilterMenuItem}>
				<div className={styles.languageFilterMenuCheckbox}>{isSelectedValue && <CheckmarkIcon className="svg" />}</div>
				<div className={styles.languageFilterLanguage}>{label}</div>
				{description ? <div className={styles.languageFilterDescription}>{description}</div> : ""}
			</div>
		)
	}
}

export default formatSelectedOption