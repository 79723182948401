import { titleCase } from "title-case";
import { normalizeBreadcrumbs } from "helpers/helpers";
import { SUPPORTED_LOCALES } from "lib/constants.mjs";
import { BreadcrumbSegment } from "@metmuseum/types";

const localeRegex = new RegExp(SUPPORTED_LOCALES
	.map(locale => `/${locale}/`)
	.join("|")
)

export const makeSegments = (originalPathname: string): BreadcrumbSegment[] => {
	if (!originalPathname) return []
	const urlPaths = originalPathname
		.replace(localeRegex, "/") // remove locale from path - it seems middleware takes care of adding this back??
		.split("/")
		.filter(Boolean) // filters empty paths ('') like the first or last slash

	return urlPaths.map((segment: string, i: number) => {
		// TODO: support internationalization lookups. example: "Audio Guide" -> "Audioguía"
		const path = "/" + urlPaths.slice(0, i + 1).join("/");
		const name = normalizeBreadcrumbs(titleCase(segment.replaceAll("-", " ")));
		return { name, path }
	})
}
