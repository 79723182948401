const customStyles = {
	control: (baseStyles, state) => ({
		...baseStyles,
		borderWidth: "1.5px",
		borderColor: state.isFocused || state.isSelected ? "var(--color-border-active)" : "var(--color-border)",
		boxShadow: "",
		":hover": {
			borderColor: 'var(--color-border-active)',
		},
	}),
	valueContainer: (baseStyles) => ({
		...baseStyles,
		paddingRight: "0"
	}),
	option: (baseStyles, state) => ({
		...baseStyles,
		backgroundColor: state.isFocused ? 'var(--color-grey-050)' : 'var(--color-white)',
		color: '#000',
		padding: "0",
		marginTop: "1px",
	}),
	menu: baseStyles => ({
		...baseStyles,
		borderRadius: '10px',
		border: "0",
		boxShadow: "0 1px 6px 2px rgba(0, 0, 0, 0.25)",
		height: "auto",
		margin: "0",
		overflow: "hidden",
		zIndex: "1000" // otherwise the hit targets are often covered
	})
}

export default customStyles