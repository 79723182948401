"use client";

import PropTypes from "prop-types";
import Select from 'react-select';
import { useRouter, usePathname } from "src/navigation";
import { SUPPORTED_LANGUAGES } from "lib/constants.mjs";
import customStyles from "./customStyles";
import DropdownIndicator from "./dropdownIndicator";
import formatSelectedOption from "./formatSelectedOption";
import styles from "./language-filter.module.scss";
import { useTranslations } from "next-intl";
import gtmEventHandler from "lib/gtmEventHandler";
import ScreenReaderOnly from "components/accesibility/screen-reader-only";
import { useLocale } from 'next-intl';
const LanguageFilter = ({
  descriptions = [],
  selectedLanguage,
  defaultMenuIsOpen = false,
  availableLanguages = []
}) => {
  const t = useTranslations();
  const lang = useLocale();
  const currentLang = selectedLanguage || lang || "en";

  //Turn the output from Sanity into a string array
  const availableLanguageCodes = availableLanguages.map(lang => lang?.language);
  const options = SUPPORTED_LANGUAGES
  // Filter out unavailable languages if available languages is populated
  .filter(lang => !availableLanguageCodes.length || availableLanguageCodes.includes(lang.id)).map(lang => {
    const {
      id,
      localName
    } = lang;
    const langDescription = descriptions.find(x => x.id == id)?.description;
    return {
      value: id,
      label: localName,
      description: langDescription
    };
  });
  const selectedLanguageItem = options.find(x => x.value == currentLang) || options.find(x => x.value == "en"); // default to english

  const router = useRouter();
  const path = usePathname();
  // const query = useSearchParams() TODO? add this back if we need it?
  const handleSelect = languageItem => {
    gtmEventHandler.pushToDataLayer({
      event: 'select_language',
      'language_selected': languageItem.value
    });
    router.push(path, {
      locale: languageItem.value
    });
  };
  return <div key={currentLang} data-sentry-component="LanguageFilter" data-sentry-source-file="index.jsx">
			<ScreenReaderOnly Element="label" text={t('selectLanguage')} htmlFor="langSelect" data-sentry-element="ScreenReaderOnly" data-sentry-source-file="index.jsx" />
			<Select id="language-select" name="langSelect" inputId="langSelect" instanceId="langSelect" defaultValue={selectedLanguageItem} defaultMenuIsOpen={defaultMenuIsOpen} onChange={handleSelect} options={options} formatOptionLabel={formatSelectedOption} isSearchable={false} maxMenuHeight={1000}
    // a boatload of styles need to be overridden
    // sadly, this is the recommended way to do this
    styles={customStyles}
    // class names for additional styles
    classNames={{
      container: () => `${styles.languageFilterContainer} language-filter-container`,
      control: () => styles.languageFilterControl,
      indicatorSeparator: () => styles.languageFilterIndicatorSeparator,
      dropdownIndicator: () => styles.chevron,
      menu: () => styles.languageFilterMenu,
      menuList: () => styles.languageFilterMenuList
    }} components={{
      DropdownIndicator
    }} data-sentry-element="Select" data-sentry-source-file="index.jsx" />
		</div>;
};
LanguageFilter.propTypes = {
  descriptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    descriptions: PropTypes.string
  })),
  selectedLanguage: PropTypes.string,
  defaultMenuIsOpen: PropTypes.bool,
  // Added only for storybook
  availableLanguages: PropTypes.arrayOf(PropTypes.shape({
    language: PropTypes.string
  }))
};
export default LanguageFilter;