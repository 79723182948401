'use client';

import Link from "next/link";
import styles from "./breadcrumbs.module.scss";
import HomeIcon from "assets/icons/home.svg";
import { usePathname } from 'next/navigation';
import { makeSegments } from "lib/makeSegments";
import MarkdownParser from "components/rich-text/markdown-parser";
import type { BreadcrumbSegment } from "@metmuseum/types";
const Breadcrumbs = ({
  segments,
  lastSegmentOverride
}: Props) => {
  const pathname = usePathname();
  const urlSegments = [...(segments || makeSegments(pathname))]; // make a copy so we don't mutate the original

  if (!urlSegments?.length) {
    return null;
  }
  const lastItem = urlSegments.pop();
  return <nav className={styles.breadcrumbs} aria-label="breadcrumbs" data-sentry-component="Breadcrumbs" data-sentry-source-file="index.tsx">
			<span key={"home"} className={styles.linkWrapper}>
				<Link aria-label="home" className={styles.link} href="/" data-sentry-element="Link" data-sentry-source-file="index.tsx">
					<HomeIcon className={styles.svg} data-sentry-element="HomeIcon" data-sentry-source-file="index.tsx" />
				</Link>
			</span>
			{urlSegments.map((segment: BreadcrumbSegment) => {
      return <span key={segment.path} className={styles.linkWrapper}>
						<Link aria-label={segment.name} className={styles.link} href={segment.path}>
							<MarkdownParser>{segment.name}</MarkdownParser>
						</Link>
					</span>;
    })}
			<span key={lastItem.path} className={styles.lastItem}>
				<MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="index.tsx">{lastSegmentOverride || lastItem.name}</MarkdownParser>
			</span>
		</nav>;
};
type Props = {
  segments?: BreadcrumbSegment[];
  lastSegmentOverride?: never; // intented to only be used when the rest of the breadcrumbs are automatically generated
} | {
  segments?: never;
  lastSegmentOverride?: string;
};
export default Breadcrumbs;