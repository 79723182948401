import PropTypes from "prop-types";
import Chevron from "assets/icons/language-filter/chevron.svg";
import { components } from 'react-select';
import styles from "./language-filter.module.scss";
const DropdownIndicator = props => {
  const {
    menuIsOpen
  } = props.selectProps;
  const chevronStyle = menuIsOpen ? styles.chevronDown : "";
  return <components.DropdownIndicator {...props} data-sentry-element="unknown" data-sentry-component="DropdownIndicator" data-sentry-source-file="dropdownIndicator.jsx">
			<Chevron className={chevronStyle} data-sentry-element="Chevron" data-sentry-source-file="dropdownIndicator.jsx" />
		</components.DropdownIndicator>;
};
DropdownIndicator.propTypes = {
  selectProps: PropTypes.shape({
    menuIsOpen: PropTypes.bool
  })
};
export default DropdownIndicator;